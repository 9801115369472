import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Casa Del Ritmo
			</title>
			<meta name={"description"} content={"Feel the rhythm, feel the vibe, and let Casa Del Ritmo be your Cuban guide!"} />
			<meta property={"og:title"} content={"Home | Casa Del Ritmo"} />
			<meta property={"og:description"} content={"Feel the rhythm, feel the vibe, and let Casa Del Ritmo be your Cuban guide!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Sports-Social-Green-Hills-1024x1024.jpeg?v=2023-10-23T11:42:12.119Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"} />
		</Helmet>
		<Components.TukazaeHeader />
		<Section padding="112px 0 0px 0" background="linear-gradient(0deg,rgba(0, 0, 0, 0.5) 0%,rgba(0, 0, 0, 0.7) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Sports-Social-Green-Hills-1024x1024.jpeg?v=2023-10-23T11:42:12.119Z) 0% 0% /auto repeat scroll padding-box" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					🍹Welcome to Casa Del Ritmo!
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					Discover the authentic essence of Cuba in every corner of our bar. With every sip of our signature cocktails and each note from our live music nights, you'll be transported to the vibrant streets of Havana.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contact-us"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="--color-secondary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						sm-width="100%"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Book a Table
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11:47:26.776Z"
					width="555px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
					srcSet="https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11%3A47%3A26.776Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11%3A47%3A26.776Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11%3A47%3A26.776Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11%3A47%3A26.776Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11%3A47%3A26.776Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11%3A47%3A26.776Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/Group%2011.png?v=2023-10-23T11%3A47%3A26.776Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 80px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-width="100%"
				md-margin="0px 0px 50px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					About Us
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL1" font="--lead" lg-text-align="center">
					Our passion for Cuban culture is what gave birth to Casa Del Ritmo. Here, we celebrate the age-old traditions, infusing them with a touch of the contemporary. From the pulsating beats of classic Cuban rhythms to the rich aroma of hand-rolled cigars, this isn't just a bar – it's a haven for stories, laughter, and those spontaneous moments that turn strangers into friends. All our guests are welcomed with vibrant culture and warmth of the Cuban spirit.
				</Text>
			</Box>
			<Box
				width="50%"
				padding="0px 0px 0px 32px"
				lg-width="100%"
				display="flex"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(3, 180px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="32px"
					max-width="600px"
					md-grid-template-rows="repeat(3, 25vw)"
					sm-grid-gap="16px"
				>
					<Image
						src="https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/counter_5.2.jpg?v=2023-10-23T12:47:59.925Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="1 / span 1"
						grid-row="1 / span 2"
						srcSet="https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/counter_5.2.jpg?v=2023-10-23T12%3A47%3A59.925Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/counter_5.2.jpg?v=2023-10-23T12%3A47%3A59.925Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/counter_5.2.jpg?v=2023-10-23T12%3A47%3A59.925Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/counter_5.2.jpg?v=2023-10-23T12%3A47%3A59.925Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/counter_5.2.jpg?v=2023-10-23T12%3A47%3A59.925Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/counter_5.2.jpg?v=2023-10-23T12%3A47%3A59.925Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/counter_5.2.jpg?v=2023-10-23T12%3A47%3A59.925Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/corner-pub-downtown-nashville.jpg?v=2023-10-23T12:47:59.874Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="2 / span 1"
						grid-row="2 / span 2"
						srcSet="https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/corner-pub-downtown-nashville.jpg?v=2023-10-23T12%3A47%3A59.874Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/corner-pub-downtown-nashville.jpg?v=2023-10-23T12%3A47%3A59.874Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/corner-pub-downtown-nashville.jpg?v=2023-10-23T12%3A47%3A59.874Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/corner-pub-downtown-nashville.jpg?v=2023-10-23T12%3A47%3A59.874Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/corner-pub-downtown-nashville.jpg?v=2023-10-23T12%3A47%3A59.874Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/corner-pub-downtown-nashville.jpg?v=2023-10-23T12%3A47%3A59.874Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/corner-pub-downtown-nashville.jpg?v=2023-10-23T12%3A47%3A59.874Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" align-content="flex-start">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 16px 0px" font="--headline1" text-align="center" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					Unique Quirks
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="start"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/interior_0.2.jpg?v=2023-10-23T12:46:33.479Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/interior_0.2.jpg?v=2023-10-23T12%3A46%3A33.479Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/interior_0.2.jpg?v=2023-10-23T12%3A46%3A33.479Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/interior_0.2.jpg?v=2023-10-23T12%3A46%3A33.479Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/interior_0.2.jpg?v=2023-10-23T12%3A46%3A33.479Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/interior_0.2.jpg?v=2023-10-23T12%3A46%3A33.479Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/interior_0.2.jpg?v=2023-10-23T12%3A46%3A33.479Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/interior_0.2.jpg?v=2023-10-23T12%3A46%3A33.479Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							🎵 Vinyl Corner
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Browse through our collection of classic Cuban vinyl records. If a tune catches your ear, let us know, and we'll let it serenade the bar.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					position="relative"
				>
					<Image
						src="https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/Sports-Social-Green-Hills-1024x1024.jpeg?v=2023-10-23T11:42:12.119Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/Sports-Social-Green-Hills-1024x1024.jpeg?v=2023-10-23T11%3A42%3A12.119Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/Sports-Social-Green-Hills-1024x1024.jpeg?v=2023-10-23T11%3A42%3A12.119Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/Sports-Social-Green-Hills-1024x1024.jpeg?v=2023-10-23T11%3A42%3A12.119Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/Sports-Social-Green-Hills-1024x1024.jpeg?v=2023-10-23T11%3A42%3A12.119Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/Sports-Social-Green-Hills-1024x1024.jpeg?v=2023-10-23T11%3A42%3A12.119Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/Sports-Social-Green-Hills-1024x1024.jpeg?v=2023-10-23T11%3A42%3A12.119Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/Sports-Social-Green-Hills-1024x1024.jpeg?v=2023-10-23T11%3A42%3A12.119Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							⚽ Sports Corner
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							A tribute to the athletic spirit of Cuba. Adorned with vintage sports memorabilia, it's a corner where sports enthusiasts can revel in the glory of past matches.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/counter_5.1.jpg?v=2023-10-23T12:46:33.432Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
						height="278px"
						srcSet="https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/counter_5.1.jpg?v=2023-10-23T12%3A46%3A33.432Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/counter_5.1.jpg?v=2023-10-23T12%3A46%3A33.432Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/counter_5.1.jpg?v=2023-10-23T12%3A46%3A33.432Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/counter_5.1.jpg?v=2023-10-23T12%3A46%3A33.432Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/counter_5.1.jpg?v=2023-10-23T12%3A46%3A33.432Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/counter_5.1.jpg?v=2023-10-23T12%3A46%3A33.432Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/counter_5.1.jpg?v=2023-10-23T12%3A46%3A33.432Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							🎨 Wall of Stories
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Behind every drink is a story waiting to be told. Share yours, and see it become a part of our bar's legacy.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" align-content="flex-start" id="menu">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 16px 0px" font="--headline1" text-align="center" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					Menu Highlights
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="start"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_5.4.jpg?v=2023-10-23T12:41:44.830Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_5.4.jpg?v=2023-10-23T12%3A41%3A44.830Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_5.4.jpg?v=2023-10-23T12%3A41%3A44.830Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_5.4.jpg?v=2023-10-23T12%3A41%3A44.830Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_5.4.jpg?v=2023-10-23T12%3A41%3A44.830Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_5.4.jpg?v=2023-10-23T12%3A41%3A44.830Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_5.4.jpg?v=2023-10-23T12%3A41%3A44.830Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_5.4.jpg?v=2023-10-23T12%3A41%3A44.830Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							🍹 El Clásico
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Our signature cocktail, a harmonious blend of aged rum, zesty lime, and that mysterious ingredient guests can't get enough of.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					position="relative"
				>
					<Image
						src="https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_4.3.jpg?v=2023-10-23T12:42:00.456Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_4.3.jpg?v=2023-10-23T12%3A42%3A00.456Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_4.3.jpg?v=2023-10-23T12%3A42%3A00.456Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_4.3.jpg?v=2023-10-23T12%3A42%3A00.456Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_4.3.jpg?v=2023-10-23T12%3A42%3A00.456Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_4.3.jpg?v=2023-10-23T12%3A42%3A00.456Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_4.3.jpg?v=2023-10-23T12%3A42%3A00.456Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_4.3.jpg?v=2023-10-23T12%3A42%3A00.456Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							☕ Café Con Conversación
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Dive deep into the rich flavours of traditional Cuban coffee. Best enjoyed while striking up a conversation with someone new.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="0px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_3.2.jpg?v=2023-10-23T12:42:16.695Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
						height="278px"
						srcSet="https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_3.2.jpg?v=2023-10-23T12%3A42%3A16.695Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_3.2.jpg?v=2023-10-23T12%3A42%3A16.695Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_3.2.jpg?v=2023-10-23T12%3A42%3A16.695Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_3.2.jpg?v=2023-10-23T12%3A42%3A16.695Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_3.2.jpg?v=2023-10-23T12%3A42%3A16.695Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_3.2.jpg?v=2023-10-23T12%3A42%3A16.695Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65365b4547aa25001fb0ef04/images/menu_3.2.jpg?v=2023-10-23T12%3A42%3A16.695Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							🍴 Taste of Havana
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Bite-sized delights bursting with the essence of Cuba. Made for sharing, savouring, and reminiscing.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="112px 0 0px 0" background="--color-darkL2" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 40px 0px"
				padding="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Events & Happenings
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					width="80%"
				>
					Every Friday and Saturday, let the soulful tunes of our live Cuban bands transport you to the heart of Havana. And watch out for spontaneous performances from professional Cuban-style dancers who'll set the floor ablaze with their moves.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s linear 0s"
					hover-background="--color-orange"
					transition="background-color 0.2s linear 0s"
				>
					Visit Us
				</Link>
			</Box>
		</Section>
		<Components.TukazaeFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});